import request from '@/util/request'

/**
 * @description 获取公寓列表接口
 * @param {number} [data.clazzId] 班级id
 * @param {number} data.sex 性别
 * @param {number} data.edu 学历
 */
export function getApartment (data) {
  return request({
    url: '/s/choose/lay/getApartment',
    method: 'post',
    data
  })
}

/**
 * @description 获取寝室列表接口
 * @param {number} data.apartmentId 公寓Id
 * @param {number} [data.clazzId] 班级Id
 * @param {number} data.sex 性别
 * @param {number} data.edu 学历
 */
export function getBedroom (data) {
  return request({
    url: '/s/choose/lay/getBedroom',
    method: 'post',
    data
  })
}

/**
 * @description 入住
 * @param {number} data.bedroomId 寝室Id
 * @param {number} data.studentId 学生Id
 * @param {number} [data.clazzId] 班级Id
 * @param {number} [data.edu] 学历
 */
export function intoBed (data) {
  return request({
    url: '/s/choose/lay/intoBed',
    method: 'post',
    data
  })
}

/**
 * @description 寝室入住详情
 * @param {number} data.studentId 学生Id
 */
export function bedBill (data) {
  return request({
    url: '/s/choose/lay/bedBill',
    method: 'post',
    data
  })
}

/**
 * @description 获取公寓列表接口
 */
export function getApartmentByTeacher (data) {
  return request({
    url: '/t/choose/lay/getApartment',
    method: 'post',
    data
  })
}

/**
 * @description 公寓数据统计
 * @param {number | ''} data.apartmentId 公寓id
 */
export function bedroomTotal (data) {
  return request({
    url: '/t/choose/lay/bedroomTotal',
    method: 'post',
    data
  })
}

/**
 * @description 获取楼层数据列表接口
 * @param {number} data.apartmentId 公寓id
 */
export function getFloor (data) {
  return request({
    url: '/t/choose/lay/getFloor',
    method: 'post',
    data
  })
}

/**
 * @description 楼层内寝室列表
 * @param {number} data.apartmentId 公寓id
 * @param {number} data.floor 楼层
 * @param {string} data.lou 楼
 */
export function getFloorBedroom (data) {
  return request({
    url: '/t/choose/lay/getFloorBedroom',
    method: 'post',
    data
  })
}

/**
 * @description 获取寝室入住详情
 * @param {number} data.bedroomId 寝室id
 */
export function getBedroomStudent (data) {
  return request({
    url: '/t/choose/lay/bedroomStudent',
    method: 'post',
    data
  })
}
