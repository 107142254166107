<template>
  <div class="page flex_column">
    <div class="top_bar flex_align scroll_x">
      <div class="flex_1" v-for="item in topBar.button" :key="item.value" >
        <button class="gradient_button gradient_button_middle flex_1" :class="item.value === topBar.value.value ? 'gradient_button_blue' : 'gradient_button_white'" @click="topBarClick(item)">
          {{ item.label }}
        </button>
      </div>
    </div>
    <div class="status flex_column flex_stretch">
      <div class="title"><span class="title_text">寝室数据统计</span></div>
      <div class="chart flex_stretch flex_center">
        <div class="circle">
          <x-circle :percent="percent" :strokeWidth="8" :trailWidth="6" :stroke-color="['#8CBAF6', '#75AFFF']" trail-color="#F0F0F0">
            <div class="flex_column">
              <div class="top">入住人数</div>
              <div class="num">{{ num }}</div>
              <div class="percent">{{ percent }}%</div>
            </div>
          </x-circle>
        </div>
      </div>
      <div class="flex_between flex_stretch flex_wrap">
        <div class="info_one flex_column flex_center" v-for="item in info" :key="item.label">
          <div class="label">{{ item.label }}</div>
          <div class="value">{{ item.value + item.unit }}</div>
        </div>
      </div>
      <div class="bottom" v-show="topBar.value.value !== ''">
        <van-button class="gradient_button gradient_button_blue" type="primary" @click="go" :disabled="topBar.value.value === ''">查看详情</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import myCircle from '@/components/circle'
import { bedroomTotal, getApartmentByTeacher } from '@/api/dormitory'
export default {
  name: 'totalStatus',
  data () {
    return {
      topBar: {
        value: { label: '全部', value: '' },
        button: [
          { label: '全部', value: '' }
          // { label: '金山公寓', value: '金山公寓' },
          // { label: '教育宾馆', value: '教育宾馆' },
          // { label: '教育宾馆育宾馆', value: '教育宾馆1' },
          // { label: '教育宾馆育宾', value: '教育宾馆2' },
          // { label: '教育宾馆育宾', value: '教育宾馆3' },
          // { label: '教育宾馆育宾', value: '教育宾馆66' }
        ]
      },
      info: [
        { label: '总寝室数', value: 0, unit: '间' },
        { label: '床位数', value: 0, unit: '' },
        { label: '整间空余', value: 0, unit: '间' },
        { label: '剩余床位', value: 0, unit: '' }
      ],
      percent: 0,
      num: 0
    }
  },
  created () {
    getApartmentByTeacher({}).then(res => {
      const { data: r } = res
      const { data, msg, code } = r
      if (code === 0) {
        data.filter(item => {
          const { name: label, id: value } = item
          this.topBar.button.push({ label, value })
        })
      } else {
        this.$toast.fail(msg)
      }
    })
    this.getData()
  },
  methods: {
    getData () {
      const data = {
        apartmentId: this.topBar.value.value
      }
      bedroomTotal(data).then(res => {
        const { data: r } = res
        const { msg, code, data } = r
        if (code === 0) {
          const { studentNum, checkIn } = data
          this.num = checkIn
          this.percent = Math.round((checkIn / studentNum) * 100)
          const m = ['total', 'bed', 'empty', 'left']
          for (let i = 0; i < m.length; i++) {
            this.info[i].value = data[m[i]]
          }
        } else {
          this.$toast.fail(msg)
        }
      })
    },
    topBarClick (item) {
      if (item.value !== this.topBar.value.value) {
        this.topBar.value = item
        this.getData()
      }
    },
    go () {
      this.$router.push({ name: 'areaStatus', params: { area: this.topBar.value.value } })
    }
  },
  components: {
    xCircle: myCircle
  }
}
</script>

<style scoped lang="less">
@import '../../assets/button';
.page {
  background: #EDF0F5;
  .top_bar {
    padding: 15px 20px;
    background: #FFF;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
    border-radius: 7px;
    overflow-x: auto;
    flex-wrap: nowrap;
    margin: 20px 20px 0;
  }
  .status {
    padding: 30px 20px;
    align-items: stretch;
    .title {
      min-width: 168px; height: 37px;
      background: url("../../pic/dormitoryStatusTitle.png") no-repeat;
      background-size: auto 100%;
      position: relative;
      .title_text {
        color: #000;
        font-size: 19px;
        position: absolute;
        top: 2px; left: 30px;
      }
    }
    .chart {
      height: 288px;
      margin-top: 20px;
      background: #FFF;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
      opacity: 0.88;
      border-radius: 26px 26px 4px 4px;
      .circle {
        width: 196px; height: 196px;
        .top {
          color: #8C9198;
          font-size: 16px;
        }
        .num {
          color: #3D444D;
          font-size: 49px;
        }
        .percent {
          color: #BBBFC5;
          font-size: 26px;
        }
      }
    }
    .info_one {
      width: 163px; height: 75px;
      margin-top: 10px;
      background: #FFF;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
      border-radius: 16px;
      .label {
        font-size: 14px;
        color: #413A71;
      }
      .value {
        font-size: 11px;
        color: #7A769C;
        margin-top: 5px;
      }
    }
    .bottom {
      margin: 40px 30px 0;
      &/deep/ .van-button__text {
        font-size: 15px;
      }
    }
  }
}
.gradient_button {
  padding: 0 10px;
  white-space:nowrap;
}
</style>
